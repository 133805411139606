.block .card-grid-image .tnt-headline {
    text-align: center;
}

.block .card-grid-image .grid-container {
    display: grid; 
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    align-items: center;
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.block .card-grid-image .grid-item .tnt-border {
    padding: 0;
    border-style: solid;
    border-width: 1px;
    border-color: #ccc;
}

.block .card-grid-image .grid-item img {
    max-height: 150px;
    object-fit: contain;
}